import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getItemsWithPagination,
  initialPaginationState,
  paginatedRequestFulfilled
} from "../../utils";

// hooks

export function useCreators() {
  const dispatch = useDispatch();
  const creators = useSelector(state => state.user.creators);
  const platformId = useSelector(state => state.frontend.platformData?.id);
  useEffect(() => {
    if (platformId && creators.length === 0) {
      dispatch(fetchAllCreatorsAsync());
    }
  }, [dispatch, platformId, creators]);
  return creators;
}

// selectors

export function selectCreatorById(id) {
  // id may come form router params as string, coerce to number
  return state => state.user.creators.find(u => u.id === Number(id)) || null;
}

// actions

export const readAllUsersForDashboardAsync = createAsyncThunk(
  "user/readAllForDashboard",
  async (payload = {}, { getState }) => {
    try {
      const {
        user: { metadata }
      } = getState();
      const response = await getItemsWithPagination("/api/admin/users", payload, metadata);
      return { users: response.data };
    } catch (error) {
      console.log(error);
    }
  }
);

export const fetchAllCreatorsAsync = createAsyncThunk(
  "user/fetchAllCreators",
  async (payload, { getState }) => {
    try {
      const platformId = getState().frontend.platformData?.id;
      const response = await axios.get("/api/user/creators?PlatformId=" + platformId);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const bulkEditUsersAsync = createAsyncThunk(
  "user/bulkEdit",
  async (payload, { dispatch }) => {
    try {
      await axios.post("/api/user/bulk-edit", {
        ids: payload.ids,
        fields: payload.fields
      });
      dispatch(readAllUsersForDashboardAsync());
      return true;
    } catch (error) {
      console.log(error);
    }
  }
);

export const bulkDeleteUsersAsync = createAsyncThunk(
  "user/bulkDelete",
  async (payload, { dispatch }) => {
    try {
      await axios.post("/api/user/bulk-delete", {
        ids: payload
      });
      dispatch(readAllUsersForDashboardAsync());
      return true;
    } catch (error) {
      console.log(error);
    }
  }
);

export const upsertUserAsync = createAsyncThunk(
  "user/upsert",
  async (payload, { rejectWithValue }) => {
    try {
      const body = payload.user;
      if (payload.PlatformId) body.PlatformId = payload.PlatformId;
      const response = await axios.post(`/api/user`, body);
      return { user: response.data };
    } catch (error) {
      if ("string" === typeof error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data.errors);
      }
    }
  }
);

export const deleteUserAsync = createAsyncThunk("/user/delete", async payload => {
  await axios.delete(`/api/user/${payload.user.id}`);
  return { id: payload.user.id };
});

export const getUsersCSVAsync = createAsyncThunk("user/csv", async (payload, extra) => {
  //const { filters, sorting } = extra.getState().seller;

  const response = await axios({
    url: "/api/user/export",
    method: "GET",
    responseType: "blob" // important
    //params: { filters, sorting },
  });

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "users.csv");
  document.body.appendChild(link);
  link.click();

  //return response.data;
});

export const getAdminsAsync = createAsyncThunk("user/getAdmins", async payload => {
  try {
    const response = await axios.get(`/api/user/admins`, {
      params: { PlatformId: payload.PlatformId }
    });
    return { users: response.data };
  } catch (error) {
    console.error("Failed to get admins.", error);
    // forward error, otherwise it executes in getAdminsAsync.fulfilled
    throw error;
  }
});

export const banUserAsync = createAsyncThunk("/user/ban", async payload => {
  await axios.delete(`/api/user/ban/${payload.id}`);
  return { id: payload.id };
});

export const readAllUserTagsAsync = createAsyncThunk("userTags/readAll", async (payload = {}) => {
  try {
    const response = await axios.get("/api/user-tags", payload);
    return { userTags: response.data };
  } catch (error) {
    console.error("Failed to get user tags.", error);
    // forward error, otherwise it executes in readAllUserTagsAsync.fulfilled
    throw error;
  }
});

export const quickCreateUserTagAsync = createAsyncThunk(
  "userTags/quickCreate",
  async (payload, { getState, rejectWithValue, dispatch }) => {
    const { frontend } = getState();
    const platformId = payload.PlatformId || frontend.platformData.id;
    const body = {
      title: payload.title,
      PlatformId: platformId
    };
    try {
      const response = await axios.post(`/api/user-tag`, body);
      await dispatch(readAllUserTagsAsync());
      return { tag: response.data };
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState: {
    loading: true,
    error: null,
    generalError: null,
    users: [],
    admins: [],
    creators: [],
    userTags: [],
    ...initialPaginationState
  },
  reducers: {
    clearError: (state, action) => {
      if (state.error) state.error[action.payload.field] = null;
    },
    clearErrors: state => {
      state.error = null;
      state.generalError = null;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(readAllUsersForDashboardAsync.pending, state => {
        state.loading = true;
      })
      .addCase(readAllUsersForDashboardAsync.fulfilled, (state, action) =>
        paginatedRequestFulfilled(state, action, "users")
      )
      .addCase(readAllUsersForDashboardAsync.rejected, (state, action) => {
        state.loading = false;
        //state.error = action.error;
      })
      .addCase(fetchAllCreatorsAsync.pending, state => {
        state.loading = true;
      })
      .addCase(fetchAllCreatorsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.creators = action.payload;
      })
      .addCase(fetchAllCreatorsAsync.rejected, (state, action) => {
        state.loading = false;
        //state.error = action.error;
      })
      .addCase(upsertUserAsync.pending, state => {
        state.loading = true;
      })
      .addCase(upsertUserAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(upsertUserAsync.rejected, (state, action) => {
        state.loading = false;
        if ("string" === typeof action.payload) {
          state.generalError = action.payload;
        } else {
          state.error = (action.payload || []).reduce((acc, error) => {
            acc[error.path] = `The ${error.path} field cannot be empty`;
            return acc;
          }, {});
        }
      })
      .addCase(deleteUserAsync.pending, state => {
        state.loading = true;
      })
      .addCase(deleteUserAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.users = state.users.filter(user => user.id !== action.payload.id);
      })
      .addCase(deleteUserAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(getAdminsAsync.pending, state => {
        state.loading = true;
      })
      .addCase(getAdminsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.admins = action.payload.users;
      })
      .addCase(getAdminsAsync.rejected, (state, action) => {
        state.loading = false;
        //state.error = action.error;
      })
      .addCase(banUserAsync.pending, state => {
        state.loading = true;
      })
      .addCase(banUserAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(banUserAsync.rejected, (state, action) => {
        state.loading = false;
        //state.error = action.error;
      })
      .addCase(bulkEditUsersAsync.pending, state => {
        state.loading = true;
      })
      .addCase(bulkEditUsersAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(bulkEditUsersAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(bulkDeleteUsersAsync.pending, state => {
        state.loading = true;
      })
      .addCase(bulkDeleteUsersAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(bulkDeleteUsersAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(readAllUserTagsAsync.pending, state => {
        state.loading = true;
      })
      .addCase(readAllUserTagsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.userTags = action.payload.userTags;
      })
      .addCase(readAllUserTagsAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  }
});
export const { clearError, clearErrors } = userSlice.actions;
export default userSlice.reducer;
