import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  getItemsWithPagination,
  initialPaginationState,
  paginatedRequestFulfilled
} from "../../utils";

// Selectors

/** Returns an array with id and label to pass to a select input */
export const selectTopicsAsOptions = state => {
  if (state.topic.topics)
    return state.topic.topics.map(topic => ({ id: topic.id, label: topic.name }));
  else return [];
};

// Action creators
export const readAllTopicsForDashboardAsync = createAsyncThunk(
  "topic/readAll",
  async (payload = {}, { getState }) => {
    try {
      const {
        topic: { metadata }
      } = getState();
      const response = await getItemsWithPagination("/api/admin/topics", payload, metadata);
      return { topics: response.data };
    } catch (error) {
      console.log(error);
    }
  }
);

export const upsertTopicAsync = createAsyncThunk(
  "topic/upsert",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/api/topic`, payload.topic);
      return { topic: response.data };
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const deleteTopicAsync = createAsyncThunk("/topic/delete", async payload => {
  await axios.delete(`/api/topic/${payload.topic.id}`);
  return { id: payload.topic.id };
});

// State and reducers

export const topicSlice = createSlice({
  name: "topic",
  initialState: {
    loading: true,
    error: null,
    topics: [],
    ...initialPaginationState
  },
  reducers: {
    clearError: (state, action) => {
      if (state.error) state.error[action.payload.field] = null;
    },
    clearErrors: state => {
      state.error = null;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(readAllTopicsForDashboardAsync.pending, state => {
        state.loading = true;
      })
      .addCase(readAllTopicsForDashboardAsync.fulfilled, (state, action) =>
        paginatedRequestFulfilled(state, action, "topics")
      )
      .addCase(readAllTopicsForDashboardAsync.rejected, (state, action) => {
        state.loading = false;
        //state.error = action.error;
      })
      .addCase(upsertTopicAsync.pending, state => {
        state.loading = true;
      })
      .addCase(upsertTopicAsync.fulfilled, (state, action) => {
        state.loading = false;
        // if (action.payload.topic[1]) {
        // insert
        state.topics.unshift(action.payload.topic);
        // } else {
        // update
        // state.topics = (state.topics || []).map(topic =>
        //   topic.id === action.payload.topic[0].id
        //     ? action.payload.topic[0]
        //     : topic
        // );
        // }
      })
      .addCase(upsertTopicAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload || []).reduce((acc, error) => {
          acc[error.path] = `The ${error.path} field cannot be empty`;
          return acc;
        }, {});
      })
      .addCase(deleteTopicAsync.pending, state => {
        state.loading = true;
      })
      .addCase(deleteTopicAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.topics = state.topics.filter(topic => topic.id !== action.payload.id);
      })
      .addCase(deleteTopicAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  }
});
export const { clearError, clearErrors } = topicSlice.actions;
export default topicSlice.reducer;
