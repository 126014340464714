import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  getItemsWithPagination,
  initialPaginationState,
  paginatedRequestFulfilled
} from "../../utils";

export const readAllAdsForDashboardAsync = createAsyncThunk(
  "ad/readAllForDashboard",
  async (payload = {}, { getState }) => {
    try {
      const {
        ad: { metadata }
      } = getState();
      const response = await getItemsWithPagination("/api/admin/ads", payload, metadata);
      return { ads: response.data };
    } catch (error) {
      console.log(error);
    }
  }
);

export const upsertAdAsync = createAsyncThunk("ad/upsert", async (payload, { rejectWithValue }) => {
  try {
    const response = await axios.post(`/api/ad`, payload.ad);
    return { ad: response.data };
  } catch (error) {
    return rejectWithValue(error.response.data.errors);
  }
});

export const deleteAdAsync = createAsyncThunk("/ad/delete", async payload => {
  await axios.delete(`/api/ad/${payload.ad.id}`);
  return { id: payload.ad.id };
});

export const adSlice = createSlice({
  name: "ad",
  initialState: {
    loading: true,
    error: null,
    ads: [],
    collections: [],
    ...initialPaginationState
  },
  reducers: {
    clearError: (state, action) => {
      if (state.error) state.error[action.payload.field] = null;
    },
    clearErrors: state => {
      state.error = null;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(readAllAdsForDashboardAsync.pending, state => {
        state.loading = true;
      })
      .addCase(readAllAdsForDashboardAsync.fulfilled, (state, action) =>
        paginatedRequestFulfilled(state, action, "ads")
      )
      .addCase(readAllAdsForDashboardAsync.rejected, (state, action) => {
        state.loading = false;
        //state.error = action.error;
      })
      .addCase(upsertAdAsync.pending, state => {
        state.loading = true;
      })
      .addCase(upsertAdAsync.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.ad[1]) {
          // insert
          if (action.payload.ad[0]) state.ads.unshift(action.payload.ad[0]);
        } else {
          // update
          state.ads = (state.ads || []).map(ad =>
            ad.id === action.payload.ad[0].id ? action.payload.ad[0] : ad
          );
        }
      })
      .addCase(upsertAdAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload || []).reduce((acc, error) => {
          acc[error.path] = `The ${error.path} field cannot be empty`;
          return acc;
        }, {});
      })
      .addCase(deleteAdAsync.pending, state => {
        state.loading = true;
      })
      .addCase(deleteAdAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.ads = state.ads.filter(ad => ad.id !== action.payload.id);
      })
      .addCase(deleteAdAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  }
});
export const { clearError, clearErrors } = adSlice.actions;
export default adSlice.reducer;
