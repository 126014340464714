import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  getItemsWithPagination,
  // initialPaginationState,
  paginatedRequestFulfilled
} from "../../utils";
import { checkAuthStatusAsync } from "../auth/authSlice";

// hooks

export const useLoadPlatformDataAndCurrentUser = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    let defaultSubdomain = process.env.REACT_APP_DEFAULT_PLATFORM_SLUG || "kmw";
    let subdomain;

    // server-side rendered platform data used in production for better performance
    const ssrPlatformData = window.__tribe_platformData__;
    const isDevOrMainPreviewSite =
      window.location.host.includes("192.168.") ||
      window.location.host.includes("localhost") ||
      (window.location.host.includes("preview.tribesocial.io") &&
        !window.location.host.includes(".preview.tribesocial.io"));
    const isPreviewSiteSubdomain = window.location.host.includes(".preview.tribesocial.io");

    if (ssrPlatformData) dispatch(loadSSRPlatformData(ssrPlatformData));
    else if (isDevOrMainPreviewSite)
      dispatch(getPlatformDataAsync({ subdomain: defaultSubdomain }));
    else if (isPreviewSiteSubdomain) {
      // *.preview.tribesocial.io platform
      subdomain = window.location.host.split(".")[0];
      dispatch(getPlatformDataAsync({ subdomain }));
    } else {
      // real domain / fallback in case ssr somehow failed
      dispatch(getPlatformDataByLiveDomainAsync({ homepageUrl: window.location.host })).then(
        data => {
          subdomain = data?.payload?.platformData?.slug || defaultSubdomain;
          dispatch(getPlatformDataAsync({ subdomain }));
        }
      );
    }

    // check login status
    dispatch(checkAuthStatusAsync());
  }, [dispatch]);
};

export function useRedirectIfUserHasSingleCollectionWithSingleContent() {
  const collections = useSelector(state => state.frontend.collections);
  const history = useHistory();
  // If user has single collection with single content item, redirect there
  useEffect(() => {
    if (collections?.length === 1 && collections?.[0]?.Contents?.length === 1)
      history.replace(`/${collections[0].slug}/${collections[0].Contents[0].id}`);
  }, [collections, history]);
}

export function useCollectionPreviewBySlug(slug) {
  // preview means only first 20 items of the collection

  // might have already been fetched through the front page
  const collectionInitial = useSelector(selectCollectionBySlug(slug));
  const [collection, setCollection] = useState();
  const { id: platformId } = useSelector(state => state.frontend.platformData);

  useEffect(() => {
    // CHECK THE CORRECT VALUE ON EACH RENDER
    setCollection(collectionInitial);
  }, [collectionInitial]);

  useEffect(() => {
    // if it wasn't already fetched, fetch the collection here
    if (!collection && slug && platformId) {
      axios
        .get(`/api/collection-by-slug/${slug}/${platformId}?limit=20`)
        .then(resp => {
          setCollection(resp.data || {}); // can come back as null
        })
        .catch(console.error);
    }
  }, [slug, collection, platformId]);

  return collection;
}

// selectors

export function selectCollectionBySlug(slug) {
  return state => state.frontend.collections.find(c => c.slug === slug);
}

// actions

export const getPlatformDataAsync = createAsyncThunk("frontend/getPlatformData", async payload => {
  try {
    const { data: platformData } = await axios.get(`/api/platform/${payload.subdomain}`);
    initPlatformColors(platformData);
    return { platformData };
  } catch (error) {
    console.log(error);
  }
});

export const getPlatformContentAsync = createAsyncThunk(
  "frontend/getPlatformContent",
  async payload => {
    try {
      const { platformData } = payload;
      const [topicData, groupData, tagData] = await Promise.all([
        axios.get(`/api/topics/${platformData?.id}`),
        axios.get(`/api/groups/platform/${platformData?.id}`),
        axios.get(`/api/tag/${platformData?.id}`)
      ]);

      return {
        groups: groupData.data,
        tags: tagData?.data,
        topics: topicData.data
      };
    } catch (error) {
      console.log(error);
    }
  }
);

export const getPublicCollectionsAsync = createAsyncThunk(
  "frontend/getPublicCollections",
  async (payload = {}, { getState }) => {
    const response = await getItemsWithPagination(
      `/api/collection/public/${getState().frontend.platformData?.id}`,
      payload,
      getState().frontend.metadata
    );

    return { collections: response.data };
  }
);

export const getPlatformDataByLiveDomainAsync = createAsyncThunk(
  "frontend/getPlatformDataByLiveDomain",
  async payload => {
    try {
      const response = await axios.get(`/api/platform/by-homepage-url/${payload.homepageUrl}`);
      return { platformData: response.data };
    } catch (error) {
      console.log(error);
    }
  }
);

export const resetPlatformTokensAsync = createAsyncThunk(
  "frontend/tokens",
  async (payload, rejectWithValue) => {
    try {
      const response = await axios.patch(`/api/platform/tokens/${payload.slug}`);
      return { tokens: response.data };
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);
export const getPlatformAnalyticsAsync = createAsyncThunk(
  "frontend/analytics",
  async (payload, payloadCreator) => {
    try {
      const { id } = payloadCreator.getState().frontend.platformData;
      const response = await axios.get(`/api/platform/analytics/${id}`);
      return { ...response.data };
    } catch (error) {
      return payloadCreator.rejectWithValue(error.response.data.errors);
    }
  }
);

export const initialState = {
  loading: true,
  error: null,
  platformData: {},
  platformContent: {
    loading: true,
    tags: [],
    groups: [],
    topics: []
  },
  platformByHomepageUrl: {},
  analytics: {},
  collections: [],
  metadata: {
    currentPage: 0,
    itemsPerPage: 25,
    totalItems: 1,
    totalPages: 1,
    firstItem: 1,
    lastItem: 1,
    sort: null,
    filters: null,
    searchTerm: "",
    hasMore: true
  }
};

export const frontendSlice = createSlice({
  name: "FrontendCollections",
  initialState,
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
    goToInfinitePaginationNextPage: state => {
      state.metadata.currentPage++;
    },
    loadSSRPlatformData: (state, action) => {
      state.platformData = action.payload;
      initPlatformColors(action.payload);
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getPlatformDataAsync.pending, state => {
        state.loading = true;
      })
      .addCase(getPlatformDataAsync.fulfilled, (state, action) => {
        state.loading = false;

        state.platformData = action.payload && action.payload.platformData;
        if (!state.platformData) state.platformData = {};
        state.platformData.Topics = (action.payload && action.payload.topics) || [];

        if (!state.platformData) {
          state.platformData = {};
        }
      })
      .addCase(getPlatformDataAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(getPublicCollectionsAsync.fulfilled, (state, action) => {
        paginatedRequestFulfilled(state, action, "collections", true);
        // increase the currentPage so as to query the next page next time.
      })
      .addCase(getPlatformContentAsync.pending, state => {
        state.platformContent.loading = true;
      })
      .addCase(getPlatformContentAsync.fulfilled, (state, action) => {
        state.platformContent.loading = false;

        state.platformContent = { ...state.platformContent, ...action.payload };
        if (!state.platformContent) {
          state.platformContent = {
            topics: [],
            tags: [],
            groups: []
          };
        }

        if (!state.platformContent.topics) {
          state.platformContent.topics = [];
        }
      })
      .addCase(getPlatformContentAsync.rejected, (state, action) => {
        state.platformContent.loading = false;
        state.error = action.error;
      })
      .addCase(getPlatformDataByLiveDomainAsync.pending, state => {
        state.loading = true;
      })
      .addCase(getPlatformDataByLiveDomainAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.platformByHomepageUrl = action.payload && action.payload.platformData;
      })
      .addCase(getPlatformDataByLiveDomainAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(resetPlatformTokensAsync.pending, state => {
        state.loading = true;
      })
      .addCase(resetPlatformTokensAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.platformData.basicToken = action.payload.tokens.basicToken;
        state.platformData.premiumToken = action.payload.tokens.premiumToken;
      })
      .addCase(getPlatformAnalyticsAsync.pending, state => {
        state.loading = true;
      })
      .addCase(getPlatformAnalyticsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.analytics = action.payload;
      });
  }
});
export const { clearErrors, goToInfinitePaginationNextPage, loadSSRPlatformData } =
  frontendSlice.actions;
export default frontendSlice.reducer;

// misc

function initPlatformColors(platformData) {
  // platformData should be an object but can sometimes be null
  const heroTextColor = platformData?.heroTextColor || "#333333";
  const primaryColor = platformData?.primaryColor || "#0000ff";
  const darkColor = platformData?.darkColor || "#333333";
  const lightColor = platformData?.lightColor || "#f3f3f3";

  const docStyle = document?.documentElement?.style;
  docStyle?.setProperty("--platform-color-hero-text", heroTextColor);
  docStyle?.setProperty("--platform-color-primary", primaryColor);
  docStyle?.setProperty("--platform-color-dark", darkColor);
  docStyle?.setProperty("--platform-color-light", lightColor);
}
