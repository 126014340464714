import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const loginAsync = createAsyncThunk("auth/signin", async payload => {
  const userData = await axios.post(`/auth/signin`, payload).then(resp => resp.data);
  const userContext = await axios.get(`/api/user-context`).then(resp => resp.data);
  return { userData, userContext };
});

export const logoutAsync = createAsyncThunk("auth/signout", async payload => {
  const response = await axios.post(`/auth/signout`, payload);
  return response.data;
});

export const resetPasswordAsync = createAsyncThunk("auth/reset-password", async payload => {
  try {
    const response = await axios.post(`/auth/reset-password`, payload);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
});

export const setPasswordAsync = createAsyncThunk("auth/set-password", async payload => {
  try {
    const response = await axios.post(`/auth/set-password`, payload);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
});

export const checkAuthStatusAsync = createAsyncThunk("auth/status", async () => {
  const [userData, userContext] = await Promise.all([
    axios.get(`/auth/status`).then(resp => resp.data),
    axios.get(`/api/user-context`).then(resp => resp.data)
  ]);
  return { userData, userContext };
});

export const loginWithProviderAsync = createAsyncThunk("auth/provider-login", async payload => {
  // TEMPORARILY REWRITTEN

  /*const [userData, userContext] = await Promise.all([
    axios.post(`/auth/provider-login`, payload).then(resp => resp.data),
    axios.get(`/api/user-context`).then(resp => resp.data)
  ]);*/

  const userData = await axios.post(`/auth/provider-login`, payload).then(resp => resp.data);
  const userContext = await axios.get(`/api/user-context`).then(resp => resp.data);

  return { userData, userContext };
});

// sendOTPAsync gets the server to create an OTP and send it to the user in an email
// payload should be just { UserId }
export const sendOTPAsync = async payload => axios.post(`/auth/otp`, payload);

export const signinWithOTPAsync = createAsyncThunk("auth/signin-otp", async payload => {
  // payload should be { UserId, otp }
  const { data: userData } = await axios.post(`/auth/signin-otp`, payload);
  const { data: userContext } = await axios.get(`/api/user-context`);
  return { userData, userContext };
});

export const getUserContext = createAsyncThunk("auth/userContext", async payload => {
  const response = await axios.get(`/api/user-context`);
  return response.data;
});

export const initialState = {
  loading: true,
  error: null,
  isAuthenticated: false,
  currentUser: null,
  loginModalShowing: false,
  activeAuthForm: "signIn", // or "resetPassword", "signUp"
  redirectOnCloseTo: null,
  completedContents: []
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
    setLoginModalState: (state, action) => {
      if (action.payload.loginModalShowing !== undefined) {
        state.loginModalShowing = action.payload.loginModalShowing;
      }
      if (action.payload.activeAuthForm) {
        state.activeAuthForm = action.payload.activeAuthForm;
      }
      if (action.payload.redirectOnCloseTo) {
        state.redirectOnCloseTo = action.payload.redirectOnCloseTo;
      }
      if (action.payload.error) {
        state.error = action.payload.error;
      } else {
        state.redirectOnCloseTo = null;
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(loginAsync.pending, state => {
        state.loading = true;
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.loading = false;

        state.isAuthenticated = action.payload.userData?.isAuthenticated;
        if (!action.payload.userData?.id) {
          state.error = "Wrong username or password";
        } else {
          state.error = null;
          state.currentUser = action.payload.userData;
          state.completedContents = action.payload.userContext?.completedContents || [];
        }
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.error = "Invalid credentials";
      })
      .addCase(signinWithOTPAsync.pending, state => {
        state.loading = true;
      })
      .addCase(signinWithOTPAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = true;
        state.error = null;
        state.currentUser = action.payload.userData;
        state.completedContents = action.payload.userContext?.completedContents || [];
      })
      .addCase(signinWithOTPAsync.rejected, (state, action) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.error = "Invalid OTP";
      })
      .addCase(logoutAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(logoutAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.currentUser = null;
      })
      .addCase(logoutAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(checkAuthStatusAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(checkAuthStatusAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = action.payload.userData?.isAuthenticated;
        state.currentUser = action.payload.userData?.currentUser;

        state.completedContents = action.payload.userContext?.completedContents || [];
      })
      .addCase(checkAuthStatusAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(loginWithProviderAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = action.payload.userData?.isAuthenticated;
        if (!action.payload.userData?.user?.id) {
          state.error = "There was a problem logging you in. Try again.";
        } else {
          state.error = null;
          state.currentUser = action.payload.userData.user;
          state.completedContents = action.payload.userContext?.completedContents || [];
        }
      })
      .addCase(loginWithProviderAsync.rejected, (state, action) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.error = "Invalid credentials";
      })
      .addCase(getUserContext.fulfilled, (state, action) => {
        state.completedContents = action.payload?.completedContents || [];
      });
  }
});
export const { clearErrors, setLoginModalState } = authSlice.actions;
export default authSlice.reducer;
