import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToastNotifications } from "../../features/toast-notification/toastNotificationSlice";
import { upsertGroupAsync, clearError, readMyGroupsAsync } from "../../features/group/groupSlice";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { getAdminsAsync } from "../../features/user/userSlice";
import slugify from "slugify";
import Multiselect from "../general/Multiselect";
import NextStepsItemForm from "./NextStepsItemForm";
import { PlusIcon } from "@heroicons/react/outline";
import ImageUploaderFormField from "./utils/ImageUploaderFormField";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { Label } from "./utils/Common";
import Button from "../common/Button";
import TabNav from "../pages/dashboard/general/TabNav";

function ReorderableNextStepItems({
  contents,
  handleReorder,
  error,
  setState,
  isDraggingNextStepItem,
  setIsDraggingNextStepItem
}) {
  const onDragEnd = val => {
    if (!val.destination) return;
    const fvsCloned = Array.from(contents);
    const [reordered] = fvsCloned.splice(val.source.index, 1);
    fvsCloned.splice(val.destination.index, 0, reordered);
    handleReorder(fvsCloned);
    setIsDraggingNextStepItem(false);
  };

  return (
    <div>
      {!contents.length ? (
        <p>This group has no next steps items yet.</p>
      ) : (
        <DragDropContext onDragStart={() => setIsDraggingNextStepItem(true)} onDragEnd={onDragEnd}>
          <Droppable
            droppableId="reorder-content"
            renderClone={(provided, snapshot, rubric) => {
              return (
                <div
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                  style={provided.draggableProps.style}
                  className="border rounded-md border-gray-300 mt-2 py-6 px-4 bg-white"
                >
                  <NextStepsItemForm
                    state={contents[rubric.source.index]}
                    error={error}
                    setState={setState}
                    i={rubric.source.index}
                    isDraggingNextStepItem={isDraggingNextStepItem}
                  />
                </div>
              );
            }}
          >
            {provided => (
              <ul {...provided.droppableProps} ref={provided.innerRef}>
                {contents.map((nextStepItem, idx) => (
                  <Draggable key={idx} draggableId={`draggable-${idx}`} index={idx}>
                    {provided => (
                      <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        style={provided.draggableProps.style}
                        className="border rounded-md border-gray-300 mt-2 py-6 px-4 bg-white"
                      >
                        <NextStepsItemForm
                          state={nextStepItem}
                          error={error}
                          setState={setState}
                          i={idx}
                          isDraggingNextStepItem={isDraggingNextStepItem}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </div>
  );
}

const GroupForm = ({ model, closeModal }) => {
  const { admins } = useSelector(state => state.user);
  const { platformData } = useSelector(state => state.frontend);
  const { currentUser } = useSelector(state => state.auth);
  const tabs = ["Details", "Tabs"];
  const [tab, setTab] = useState("Details");

  const isAdmin = currentUser.role === "admin";
  const defaultState = {
    groupCreatorId: currentUser.id,
    adminIds: [currentUser.id],
    GroupNextStepItems: [],
    PlatformId: currentUser.PlatformId
  };
  const [state, setState] = useState(model || defaultState); // form state
  const { flashSuccessNotification, flashErrorNotification } = useToastNotifications();

  const { error } = useSelector(state => state.group);
  const dispatch = useDispatch();

  const onChange = event => {
    const { name, value } = event.target;
    if (error && error[name]) dispatch(clearError({ field: name }));
    setState(prevState => ({ ...prevState, [name]: value }));
  };

  const onSubmit = async event => {
    event.preventDefault();
    try {
      const group = { ...state, Users: [] };
      if (group.name) group.slug = slugify(group.name, { lower: true, strict: true });
      const action = await dispatch(upsertGroupAsync({ group }));
      if (action.error) return flashErrorNotification();
      await dispatch(readMyGroupsAsync());
      flashSuccessNotification();
      closeModal();
    } catch (error) {
      console.error("Failed to save group", error);
      flashErrorNotification();
    }
  };

  // if the form is opened from the groups page (LeftNavbar)
  // the admins are not preloaded, so we load them manually here
  useEffect(() => {
    // no need to fetch if creator makes group because they're set as the admin
    if (isAdmin) dispatch(getAdminsAsync({ PlatformId: currentUser.PlatformId }));
  }, [dispatch, currentUser.PlatformId, isAdmin]);

  const nextStepItemsContainerRef = useRef(null);

  const [isDraggingNextStepItem, setIsDraggingNextStepItem] = useState(false);

  const handleReorder = newOrder => {
    // UPDATE THE ORDER
    const GroupNextStepItems = newOrder.map((item, i) => ({ ...item, order: i }));
    setState(state => ({ ...state, GroupNextStepItems }));
  };

  return (
    <form onSubmit={event => onSubmit(event)} id="group-form">
      <TabNav tabs={tabs} activeTab={tab} onChange={setTab} />
      {tab === "Details" && (
        <div className="mt-4">
          <div>
            <Label htmlFor="name">Name</Label>
            <div className="mt-1 rounded-md shadow-sm relative">
              <input
                id="name"
                autoFocus
                name="name"
                className={`${
                  error && error.name
                    ? "border-red-300 text-red-300 focus:border-red-300 focus:shadow-outline-red"
                    : "border-gray-300 focus:shadow-outline-blue focus:border-blue-300"
                } block w-full px-3 py-2 border rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                value={(state && state.name) || ""}
                onChange={event => onChange(event)}
                placeholder="name"
              />
              {error && error.name && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <ExclamationCircleIcon className="w-5 text-red-600" />
                </div>
              )}
            </div>
            {error && error.name && (
              <p className="mt-2 text-sm text-red-600">This field is required</p>
            )}
          </div>

          <div className="my-6">
            <Label htmlFor="description">Description</Label>
            <div className="mt-1 rounded-md shadow-sm relative">
              <textarea
                id="description"
                name="description"
                className={`${
                  error && error.name
                    ? "border-red-300 text-red-300 focus:border-red-300 focus:shadow-outline-red"
                    : "border-gray-300 focus:shadow-outline-blue focus:border-blue-300"
                } block w-full px-3 py-2 border rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                value={(state && state.description) || ""}
                onChange={event => onChange(event)}
                placeholder="description"
              />
              {error && error.description && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <ExclamationCircleIcon className="w-5 text-red-600" />
                </div>
              )}
            </div>
          </div>

          <ImageUploaderFormField
            title="Cover Image"
            field="coverImg"
            value={state.coverImg}
            onChange={value => onChange({ target: { name: "coverImg", value } })}
            description={"SVG, PNG, JPG, GIF up to 10MB"}
          />

          {isAdmin && (
            <div className="mt-6">
              <Label htmlFor="adminIds">Group Admins</Label>
              <div className="mt-1 rounded-md shadow-sm relative">
                <Multiselect
                  id="adminIds"
                  name="adminIds"
                  placeholder="Choose admins"
                  onChange={onChange}
                  value={state.adminIds || []}
                  options={admins.map(a => ({ id: a.id, label: a.name }))}
                />
              </div>
              {error && error.adminIds && (
                <p className="mt-2 text-sm text-red-600">This field is required</p>
              )}
            </div>
          )}

          <div className="mt-6">
            <Label htmlFor="visibility">Visibility</Label>
            <div className="mt-1 rounded-md shadow-sm">
              <select
                id="visibility"
                className="form-select block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 border border-gray-300"
                name="visibility"
                value={(state && state.visibility) || ""}
                onChange={event => onChange(event)}
              >
                <option value="public">Public</option>
                <option value="private">Private</option>
                <option value="hidden">Hidden</option>
              </select>
            </div>
          </div>

          <div className="mt-6">
            <Label
              htmlFor="purchaseLink"
              label="Purchase link"
              tooltipContent="Add an link to your sales funnel or landing page. When a user clicks on this group they will be redirected to the URL provided. If empty, user will be directed to the group."
            />
            <div className="mt-1 rounded-md shadow-sm relative">
              <input
                id="purchaseLink"
                name="purchaseLink"
                className={`${
                  error && error.name
                    ? "border-red-300 text-red-300 focus:border-red-300 focus:shadow-outline-red"
                    : "border-gray-300 focus:shadow-outline-blue focus:border-blue-300"
                } block w-full px-3 py-2 border rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                value={(state && state.purchaseLink) || ""}
                onChange={event => onChange(event)}
                placeholder="https://purchasehere.com"
              />
              {error && error.purchaseLink && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <ExclamationCircleIcon className="w-5 text-red-600" />
                </div>
              )}
            </div>
          </div>
          <div className="mt-6">
            <Label
              htmlFor="externalGroupUrl"
              label="Group Redirect URL"
              tooltipContent="Redirect users here when they are on the group page. Leave this empty unless your group is hosted somewhere else."
            />
            <div className="mt-1 rounded-md shadow-sm relative">
              <input
                id="externalGroupUrl"
                name="externalGroupUrl"
                className={`${
                  error && error.externalGroupUrl
                    ? "border-red-300 text-red-300 focus:border-red-300 focus:shadow-outline-red"
                    : "border-gray-300 focus:shadow-outline-blue focus:border-blue-300"
                } block w-full px-3 py-2 border rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                value={(state && state.externalGroupUrl) || ""}
                onChange={event => onChange(event)}
                placeholder="https://mygroup.com"
              />
              {error && error.externalGroupUrl && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <ExclamationCircleIcon className="w-5 text-red-600" />
                </div>
              )}
            </div>
          </div>

          <div className="my-6">
            <Label htmlFor="position">Order</Label>
            <div className="mt-1 rounded-md shadow-sm relative">
              <input
                id="position"
                name="position"
                type="number"
                className={`${
                  error && error.position
                    ? "border-red-300 text-red-300 focus:border-red-300 focus:shadow-outline-red"
                    : "border-gray-300 focus:shadow-outline-blue focus:border-blue-300"
                } block w-full px-3 py-2 border rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                value={(state && state.position) || ""}
                onChange={event => onChange(event)}
                placeholder="0"
              />
              {error && error.position && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <ExclamationCircleIcon className="w-5 text-red-600" />
                </div>
              )}
            </div>
            {error && error.position && (
              <p className="mt-2 text-sm text-red-600">This field is required</p>
            )}
          </div>
        </div>
      )}
      {tab === "Tabs" && (
        <div className="mt-4">
          <div>
            <div className="grid gap-4 md:grid-cols-2 mb-4">
              <ToggleTab
                id="isCommunityTabEnabled"
                title="Community"
                description="Allow members to connect with each other and share wins or ask questions. Users can “go live” and connect live with other members. "
                isEnabled={state.isCommunityTabEnabled}
                toggleSetting={() => {
                  setState(prevState => ({
                    ...prevState,
                    isCommunityTabEnabled: !state.isCommunityTabEnabled
                  }));
                }}
              />

              {platformData.firebaseCredentials && (
                <ToggleTab
                  id="isCommunityAdminOnlyTabEnabled"
                  title="Announcements"
                  description="Similar layout to the feed tab but displays group admin posts only. Read-only for members."
                  isEnabled={state.isCommunityAdminOnlyTabEnabled}
                  toggleSetting={() => {
                    setState(prevState => ({
                      ...prevState,
                      isCommunityAdminOnlyTabEnabled: !state.isCommunityAdminOnlyTabEnabled
                    }));
                  }}
                />
              )}

              <div id="today-tab" className="hidden">
                <ToggleTab
                  id="isTodayTabEnabled"
                  title="Today"
                  description="Show the today tab"
                  isEnabled={state.isTodayTabEnabled}
                  toggleSetting={() => {
                    setState(prevState => ({
                      ...prevState,
                      isTodayTabEnabled: !state.isTodayTabEnabled
                    }));
                  }}
                />
                {state.isTodayTabEnabled && (
                  <div className="mt-2">
                    <div className="mt-6">
                      <Label htmlFor="headlineText">Headline</Label>
                      <div className="mt-1 rounded-md shadow-sm relative">
                        <input
                          id="headlineText"
                          name="headlineText"
                          className={`${
                            error && error.headlineText
                              ? "border-red-300 text-red-300 focus:border-red-300 focus:shadow-outline-red"
                              : "border-gray-300 focus:shadow-outline-blue focus:border-blue-300"
                          } block w-full px-3 py-2 border rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                          value={(state && state.headlineText) || ""}
                          onChange={event => onChange(event)}
                        />
                        {error && error.headlineText && (
                          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <ExclamationCircleIcon className="w-5 text-red-600" />
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="mt-6">
                      <Label htmlFor="headlineBtnText">CTA Button Text</Label>
                      <div className="mt-1 rounded-md shadow-sm relative">
                        <input
                          id="headlineBtnText"
                          name="headlineBtnText"
                          className={`${
                            error && error.headlineBtnText
                              ? "border-red-300 text-red-300 focus:border-red-300 focus:shadow-outline-red"
                              : "border-gray-300 focus:shadow-outline-blue focus:border-blue-300"
                          } block w-full px-3 py-2 border rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                          value={(state && state.headlineBtnText) || ""}
                          onChange={event => onChange(event)}
                        />
                        {error && error.headlineBtnText && (
                          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <ExclamationCircleIcon className="w-5 text-red-600" />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mt-6">
                      <Label htmlFor="headlineBtnUrl">CTA Button URL</Label>
                      <div className="mt-1 rounded-md shadow-sm relative">
                        <input
                          id="headlineBtnUrl"
                          name="headlineBtnUrl"
                          className={`${
                            error && error.headlineBtnUrl
                              ? "border-red-300 text-red-300 focus:border-red-300 focus:shadow-outline-red"
                              : "border-gray-300 focus:shadow-outline-blue focus:border-blue-300"
                          } block w-full px-3 py-2 border rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                          value={(state && state.headlineBtnUrl) || ""}
                          onChange={event => onChange(event)}
                        />
                        {error && error.headlineBtnUrl && (
                          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <ExclamationCircleIcon className="w-5 text-red-600" />
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="my-6">
                      <Label htmlFor="sessionHeadlineText">Subheadline</Label>
                      <div className="mt-1 rounded-md shadow-sm relative">
                        <input
                          id="sessionHeadlineText"
                          name="sessionHeadlineText"
                          className={`${
                            error && error.sessionHeadlineText
                              ? "border-red-300 text-red-300 focus:border-red-300 focus:shadow-outline-red"
                              : "border-gray-300 focus:shadow-outline-blue focus:border-blue-300"
                          } block w-full px-3 py-2 border rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                          value={(state && state.sessionHeadlineText) || ""}
                          onChange={event => onChange(event)}
                        />
                        {error && error.sessionHeadlineText && (
                          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <ExclamationCircleIcon className="w-5 text-red-600" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <ToggleTab
                id="isTrainingTabEnabled"
                title="Training"
                description="Our legacy version for a single course in your group. Organized with Collections and Content. "
                isEnabled={state.isTrainingTabEnabled}
                toggleSetting={() => {
                  setState(prevState => ({
                    ...prevState,
                    isTrainingTabEnabled: !state.isTrainingTabEnabled
                  }));
                }}
              />

              {platformData.firebaseCredentials && (
                <ToggleTab
                  id="isCoursesTabEnabled"
                  title="Courses"
                  description="Our latest version of Courses allows for multiple courses in a single group and a much better desktop and mobile experience. "
                  isEnabled={state.isCoursesTabEnabled}
                  toggleSetting={() => {
                    setState(prevState => ({
                      ...prevState,
                      isCoursesTabEnabled: !state.isCoursesTabEnabled
                    }));
                  }}
                />
              )}

              <ToggleTab
                id="isReplayTabEnabled"
                title="Replay"
                description="List weekly live call replays for your members. Content assigned to a group but not a collection will be listed."
                isEnabled={state.isReplayTabEnabled}
                toggleSetting={() => {
                  setState(prevState => ({
                    ...prevState,
                    isReplayTabEnabled: !state.isReplayTabEnabled
                  }));
                }}
              />

              <ToggleTab
                id="isEventsTabEnabled"
                title="Events"
                description="Connect a 3rd party embedded calendar to show all upcoming events for your group. We recommend AddEvent.com"
                isEnabled={state.isEventsTabEnabled}
                toggleSetting={() => {
                  setState(prevState => ({
                    ...prevState,
                    isEventsTabEnabled: !state.isEventsTabEnabled
                  }));
                }}
              >
                {state.isEventsTabEnabled && (
                  <div className="mt-6 mb-4">
                    <Label htmlFor="eventUrl">Event Embed URL</Label>
                    <div className="mt-1 rounded-md shadow-sm relative">
                      <input
                        id="eventUrl"
                        name="eventUrl"
                        className={`${
                          error && error.eventUrl
                            ? "border-red-300 text-red-300 focus:border-red-300 focus:shadow-outline-red"
                            : "border-gray-300 focus:shadow-outline-blue focus:border-blue-300"
                        } block w-full px-3 py-2 border rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                        value={(state && state.eventUrl) || ""}
                        onChange={event => onChange(event)}
                      />
                      {error && error.eventUrl && (
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <ExclamationCircleIcon className="w-5 text-red-600" />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </ToggleTab>

              <ToggleTab
                id="isMembersTabEnabled"
                title="Members"
                description="Members can search and view all members in your group."
                isEnabled={state.isMembersTabEnabled}
                toggleSetting={() => {
                  setState(prevState => ({
                    ...prevState,
                    isMembersTabEnabled: !state.isMembersTabEnabled
                  }));
                }}
              />

              {platformData.firebaseCredentials && (
                <ToggleTab
                  id="isChatbotTabEnabled"
                  title="Chatbot"
                  description="Connect a 3rd party chatbot to a dedicated tab in your group. We recommend Chatbase.co."
                  isEnabled={state.isChatbotTabEnabled}
                  toggleSetting={() => {
                    setState(prevState => ({
                      ...prevState,
                      isChatbotTabEnabled: !state.isChatbotTabEnabled
                    }));
                  }}
                >
                  {state.isChatbotTabEnabled && (
                    <div>
                      <div className="my-6">
                        <Label htmlFor="chatbotTabLabel">Chatbot Tab Label</Label>
                        <div className="mt-1 rounded-md shadow-sm relative">
                          <input
                            id="chatbotTabLabel"
                            name="chatbotTabLabel"
                            className={`${
                              error && error.chatbotTabLabel
                                ? "border-red-300 text-red-300 focus:border-red-300 focus:shadow-outline-red"
                                : "border-gray-300 focus:shadow-outline-blue focus:border-blue-300"
                            } block w-full px-3 py-2 border rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                            value={(state && state.chatbotTabLabel) || ""}
                            onChange={event => onChange(event)}
                          />
                          {error && error.chatbotTabLabel && (
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                              <ExclamationCircleIcon className="w-5 text-red-600" />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="mb-4">
                        <Label htmlFor="chatbotEmbedUrl">Chatbot Embed Url</Label>
                        <div className="mt-1 rounded-md shadow-sm relative">
                          <input
                            id="chatbotEmbedUrl"
                            name="chatbotEmbedUrl"
                            className={`${
                              error && error.chatbotEmbedUrl
                                ? "border-red-300 text-red-300 focus:border-red-300 focus:shadow-outline-red"
                                : "border-gray-300 focus:shadow-outline-blue focus:border-blue-300"
                            } block w-full px-3 py-2 border rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                            value={(state && state.chatbotEmbedUrl) || ""}
                            onChange={event => onChange(event)}
                          />
                          {error && error.chatbotEmbedUrl && (
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                              <ExclamationCircleIcon className="w-5 text-red-600" />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </ToggleTab>
              )}
            </div>

            <ToggleTab
              id="isNextStepsTabEnabled"
              title="Next Steps"
              description="Display important next steps or links for your members. "
              isEnabled={state.isNextStepsTabEnabled}
              toggleSetting={() => {
                setState(prevState => ({
                  ...prevState,
                  isNextStepsTabEnabled: !state.isNextStepsTabEnabled
                }));
              }}
            >
              {state.isNextStepsTabEnabled && (
                <div>
                  {/* NEXT STEPS ITEMS */}
                  <div className="mt-6"></div>
                  <Label>Next step items:</Label>

                  <div className="max-h-[700px] overflow-auto mb-4" ref={nextStepItemsContainerRef}>
                    <ReorderableNextStepItems
                      contents={state.GroupNextStepItems || []}
                      setState={setState}
                      handleReorder={handleReorder}
                      error={error}
                      isDraggingNextStepItem={isDraggingNextStepItem}
                      setIsDraggingNextStepItem={setIsDraggingNextStepItem}
                    />
                  </div>

                  <div className="mt-2">
                    <button
                      type="button"
                      className="w-full inline-flex justify-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-slate-700 bg-white hover:text-slate-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out"
                      onClick={() => {
                        setTimeout(() => {
                          if (nextStepItemsContainerRef?.current) {
                            // scroll to the bottom of the Next Step Items container after a little bit
                            const nextStepItemHeight = 400;
                            nextStepItemsContainerRef.current.scrollTop =
                              nextStepItemsContainerRef.current.scrollHeight + nextStepItemHeight;
                          }
                        }, 500);

                        // push a new item
                        setState(state => ({
                          ...state,
                          GroupNextStepItems: [
                            ...state.GroupNextStepItems,
                            {
                              order:
                                state.GroupNextStepItems?.length > 0
                                  ? Math.max(...state.GroupNextStepItems.map(item => item.order)) +
                                    1
                                  : 1
                            }
                          ]
                        }));
                      }}
                    >
                      <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                      Add new item
                    </button>
                  </div>
                  {/* END: NEXT STEPS ITEMS */}
                </div>
              )}
            </ToggleTab>
          </div>
        </div>
      )}

      <div className="mt-8 grid grid-cols-2 gap-3">
        <Button onClick={closeModal} variant="secondary">
          Cancel
        </Button>

        <Button type="submit" variant="primary">
          Save
        </Button>
      </div>
    </form>
  );
};

function ToggleTab({ id, title, description, isEnabled, toggleSetting, children }) {
  return (
    <div
      key={id}
      className={`bg-white rounded-lg shadow p-4 transition-opacity duration-200 ${
        isEnabled ? "opacity-100" : "opacity-50"
      }`}
    >
      <div className="">
        <div className="flex justify-between mb-2">
          <h3 className="text-lg font-medium">{title}</h3>
          <button
            onClick={e => {
              e.preventDefault();
              toggleSetting(id);
            }}
            className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:bg-blue-500 focus:ring-offset-2 ${
              isEnabled ? "bg-blue-500" : "bg-gray-200"
            }`}
            role="switch"
            aria-checked={isEnabled}
            data-testid={`${id}-toggle`}
          >
            <span
              aria-hidden="true"
              className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${
                isEnabled ? "translate-x-5" : "translate-x-0"
              }`}
            />
          </button>
        </div>
        <p className="text-sm text-gray-600">{description}</p>
      </div>
      {children}
    </div>
  );
}

export default GroupForm;
