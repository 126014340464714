import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  getItemsWithPagination,
  initialPaginationState,
  paginatedRequestFulfilled
} from "../../utils";

export const readAllContentsForDashboardAsync = createAsyncThunk(
  "content/readAllForDashboard",
  async (payload = {}, { getState }) => {
    try {
      const {
        content: { metadata }
      } = getState();
      const response = await getItemsWithPagination("/api/admin/contents", payload, metadata);
      return { contents: response.data };
    } catch (error) {
      console.log(error);
    }
  }
);

export const upsertContentAsync = createAsyncThunk(
  "content/upsert",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/api/content`, payload.content);
      return { content: response.data };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteContentAsync = createAsyncThunk("/content/delete", async payload => {
  await axios.delete(`/api/content/${payload.content.id}`);
  return { id: payload.content.id };
});

export const getTagByPlatformAsync = createAsyncThunk("/tag/getTagByPlatform", async payload => {
  const response = await axios.get(`/api/tag/${payload.PlatformId}`);
  return { tags: response.data };
});

export const contentSlice = createSlice({
  name: "content",
  initialState: {
    loading: true,
    error: null,
    contents: [],
    collections: [],
    tags: [],
    ...initialPaginationState
  },
  reducers: {
    clearError: (state, action) => {
      if (state.error) state.error[action.payload.field] = null;
    },
    clearErrors: state => {
      state.error = null;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(readAllContentsForDashboardAsync.pending, state => {
        state.loading = true;
      })
      .addCase(readAllContentsForDashboardAsync.fulfilled, (state, action) => {
        paginatedRequestFulfilled(state, action, "contents");
      })
      .addCase(readAllContentsForDashboardAsync.rejected, state => {
        state.loading = false;
        //state.error = action.error;
      })
      .addCase(upsertContentAsync.pending, state => {
        state.loading = true;
      })
      .addCase(upsertContentAsync.fulfilled, state => {
        state.loading = false;
      })
      .addCase(upsertContentAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
      })
      .addCase(deleteContentAsync.pending, state => {
        state.loading = true;
      })
      .addCase(deleteContentAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.contents = state.contents.filter(content => content.id !== action.payload.id);
      })
      .addCase(deleteContentAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(getTagByPlatformAsync.pending, state => {
        state.loading = true;
      })
      .addCase(getTagByPlatformAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.tags = action.payload.tags;
      })
      .addCase(getTagByPlatformAsync.rejected, state => {
        state.loading = false;
        //state.error = action.error;
      });
  }
});
export const { clearError, clearErrors } = contentSlice.actions;
export default contentSlice.reducer;
