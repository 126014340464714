import axios from "axios";
import qs from "qs";
import { useEffect, useRef } from "react";
import constants from "../constants.js";

export const stripHtmlTags = str => str?.replace(/<\/?[^>]+(>|$)/g, "") || "";

export const richTextToPlainText = str => {
  try {
    const parsed = JSON.parse(str);
    return parsed.map(block => block.data.text || block.data.items.join("\n")).join("\n");
  } catch {}
  return stripHtmlTags(str);
};

export function animationInterval(ms, signal, callback) {
  const start = document.timeline.currentTime;

  function frame(time) {
    if (signal.aborted) return;
    callback(time);
    scheduleFrame(time);
  }

  function scheduleFrame(time) {
    const elapsed = time - start;
    const roundedElapsed = Math.round(elapsed / ms) * ms;
    const targetNext = start + roundedElapsed + ms;
    const delay = targetNext - performance.now();
    setTimeout(() => requestAnimationFrame(frame), delay);
  }

  scheduleFrame(start);
}

export const extractNotionPageIdFromUrl = url => {
  // in a notion page url like https://inovo.notion.site/Tribe-Releases-e7aab0beb40147e8bfe525e9e422ec2d
  // the page id is e7aab0beb40147e8bfe525e9e422ec2d
  const array = url.split("-");
  return array[array.length - 1];
};

export const getItemsWithPagination = (url, params, state) => {
  return axios.get(url, {
    params: {
      currentPage: params.currentPage || state.currentPage || 1,
      filters: params.filters || state.filters || undefined,
      sort: params.sort
        ? { [params.sort.column]: params.sort.desc ? "DESC" : "ASC" }
        : state.sort
        ? { [state.sort.column]: state.sort.desc ? "DESC" : "ASC" }
        : undefined,
      itemsPerPage: params.itemsPerPage || state.itemsPerPage || 25,
      searchTerm: (params.searchTerm ?? state.searchTerm) || undefined
    },
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: "brackets" });
    }
  });
};

export const initialPaginationState = {
  metadata: {
    currentPage: 1,
    itemsPerPage: 25,
    totalItems: 1,
    totalPages: 1,
    firstItem: 1,
    lastItem: 1,
    sort: null,
    filters: null,
    searchTerm: "",
    hasMore: true
  }
};

export const paginatedRequestFulfilled = (state, action, key, merge) => {
  state.loading = false;
  const { items, ...metadata } = action.payload?.[key] || {};

  // new: merge for infinite scroll
  state[key] = merge ? [...state[key], ...items] : items || [];
  if (metadata) {
    const { currentPage, itemsPerPage, totalItems } = metadata;
    const firstItem = (currentPage - 1) * itemsPerPage + 1;
    const lastItem = currentPage * itemsPerPage;
    state.metadata = {
      ...state.metadata,
      ...metadata,
      firstItem,
      lastItem: lastItem < totalItems ? lastItem : totalItems
    };
    if (metadata.sort) {
      const [column, desc] = Object.entries(metadata.sort)[0];
      state.metadata.sort = { column, desc: desc === "DESC" };
    }
  }
};

export const defaultNoPaginationOptions = { itemsPerPage: 999, currentPage: 1, searchTerm: "" };

export const getImageSrc = path => (path ? constants.imgUrl + path : "/anon.png");

export function getImageSrcRelOrAbsolute(photo, optimizationQuery = "") {
  if (!photo) return "/anon.png";
  // either contains http and return directly
  if (photo.includes("http")) return photo;
  // or return this:
  return constants.imgUrl + photo + optimizationQuery;
}

// for troubleshooting re-renders from props changes
export function useTraceUpdate(props) {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.log("Changed props:", changedProps);
    }
    prev.current = props;
  });
}
